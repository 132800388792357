<template>
  <div>
    <Form
      v-if="fieldSettings"
      ref="form"
      :data="data"
      :fieldSettings="fieldSettings"
      :type="form.display.settings.type"
      :validation="validation"
      :layout="form.display.settings.layout"
      :widgets="form.display.settings.widgets"
      :tabs="form.display.settings.tabs"
      :fields="form.display.settings.fields"
      :widgetsIsDraggable="isDraggable"
      @onSave="editTask"
      @onAttachmentAppend="onAttachmentAppend"
    />

    <PortalHeaderGridControlls
      v-if="showGridControlls"
      @onDragClicked="onDragClicked"
    />
  </div>
</template>

<script>
import api from "@/api";
import Form from "@/components/Form/Form.vue";
import PortalHeaderGridControlls from "@/components/portals/PortalHeaderGridControlls.vue";
import { rights } from "@/data/rights";
import { formService } from "@/services/formService";
import formatLocale from "@/utils/formaters/formatLocale";
import { mapGetters, mapState } from "vuex";

export default {
  components: { Form, PortalHeaderGridControlls },
  data() {
    return {
      form: {},
      task: {},
      statuses: [],
      data: {},
      fieldSettings: null,
      validation: {},
      isDraggable: false,
    };
  },
  computed: {
    ...mapState("core$common", ["node", "forms", "users", "fields"]),
    ...mapGetters("core$common", ["getStatusesByProcessKey"]),
    ...mapGetters("core$rights", ["hasRight", "getRightAttrs"]),
    showGridControlls() {
      return this.hasRight(rights.LayoutEdit);
    },
  },
  methods: {
    init() {
      this.statuses = this.getStatusesByProcessKey(
        this.$route.params.processKey
      );
      this.form = this.forms.find((form) => {
        return (
          form.type === "edit" &&
          form.processes.some((f) => f.key === this.$route.params.processKey)
        );
      });

      if (!this.form) {
        return this.$alert({
          type: "danger",
          text: this.$t("form_not_founded"),
        });
      }

      this.getTask();
    },
    getTask() {
      return api
        .request({
          name: "getTaskByKey",
          pathParam: {
            nodeId: this.node.id,
            taskKey: this.$route.params.taskKey,
          },
        })
        .then((resp) => {
          this.task = resp.data.data;

          this.data = formService.deserialize({
            data: this.task,
            fields: this.fields,
          });

          this.validation = formService.getValidation(this.form.validators);
          this.fieldSettings = formService.getFieldSettings({
            form: this.form,
            fields: this.fields,
            statuses: this.statuses,
            users: this.users,
            editableFields: this.getRightAttrs(rights.FieldsEdit),
            visibleFields: this.getRightAttrs(rights.FieldsView),
            isFormReadonly: !this.hasRight(rights.TaskEdit),
            toLocaleMethod: formatLocale,
          });
        });
    },
    editTask() {
      const payload = formService.serialize(this.$refs.form.getFormData());

      return api.request({
        name: "editTask",
        pathParam: { nodeId: this.node.id, taskId: this.task.id },
        payload,
        showAlert: true,
      });
    },
    async onAttachmentAppend(files) {
      const requests = Array.from(files).map((file) => {
        const formData = new FormData();
        formData.append("attachment", file);
        return api.request({
          name: "createAttachment",
          pathParam: {
            nodeId: this.node.id,
            taskId: this.task.id,
            type: "task",
          },
          payload: formData,
        });
      });
      await Promise.all(requests);
      this.getTask();
    },
    onAttachmentDelete(file) {
      api
        .request({
          name: "deleteAttachment",
          pathParam: { nodeId: this.node.id, attachmentId: file.id },
          showAlert: true,
        })
        .then(this.init);
    },
    layoutIsChanged() {
      const payload = Object.assign({}, this.form.display);

      api.request({
        name: "editForm",
        pathParam: { nodeId: this.node.id, formId: this.form.id },
        payload: { display: payload },
        showAlert: true,
      });
    },
    onDragClicked(e) {
      this.isDraggable = e;
      if (e) return;

      this.layoutIsChanged();
    },
  },
  created() {
    this.init();
  },
};
</script>

<style scoped lang="scss"></style>
